import React from "react";

import ImageCarousel from "./ImageCarousel";
import Heading from "./Heading";
import Price from "./Price";
import Details from "./Details";

const SpecialContainer = ({ special }) => {
  const photos =
    special.photos.length && special.photos[0].length ? (
      <ImageCarousel images={special.photos} />
    ) : (
      <div className="pb-4">
        <div className="embed-responsive embed-responsive-4by3">
          <div className="embed-responsive-item bg-blue-800 d-flex align-items-center justify-content-center">
            <div className="h-32 w-32 opacity-10">
              <img
                className="h-100 w-100 object-contain"
                alt=""
                src="/assets/img/wh-silhouette-white.png"
              />
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="bg-white rounded shadow-sm overflow-hidden h-100 d-flex flex-column">
      <div>{photos}</div>
      <div className="d-flex flex-column flex-grow-1">
        <Heading special={special} vehicle={special.vehicle} />
        <Price
          leftColClass="py-2"
          rightColClass="py-2 border-left"
          className="border-bottom px-4"
          special={special}
        />
        <Details
          special={special}
          id={special.id}
          specialBullets={special.bullets}
        />
      </div>
    </div>
  );
};

export default SpecialContainer;
