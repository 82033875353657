import React, { useContext } from "react";

import { Form } from "react-bootstrap";
import { MainContext } from "../../Context/MainContext";

const DesktopSortContainer = () => {
  const { MainState, handleSortSelect } = useContext(MainContext);

  const sortOptions = MainState.sortOptions;
  const sortValue = MainState.sortValue;

  const handleChange = (e) => {
    handleSortSelect(e.target.value);
  };

  return (
    <div className="form-inline">
      <Form.Group controlId="sortResults">
        <Form.Label className="mr-2 text-blue-600 text-sm font-weight-bold">
          Sort by:
        </Form.Label>
        <Form.Control
          className="border-slate-500"
          value={sortValue}
          onChange={handleChange}
          as="select"
          custom
        >
          <option value="" disabled>
            -- Sort Specials --
          </option>
          {sortOptions.map((option) => (
            <option key={option.value} value={option.value}>
              {option.text}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    </div>
  );
};

export default DesktopSortContainer;
