import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";

const DropdownToggle = ({ labels, handleToggle }) => {
  const [viewing, setViewing] = useState(labels[0].value);

  useEffect(() => {
    handleToggle(viewing);
  }, [viewing]);

  return (
    <div className="mx-n3">
      {labels.map((label, idx) => (
        <Button
          key={idx}
          className="py-0 text-decoration-none shadow-none border-0 px-3 transition-fast"
          variant={viewing === label.value ? "link-blue-600" : "link-slate-500"}
          onClick={() => setViewing(label.value)}
        >
          <div
            className={`${
              viewing === label.value ? "border-blue-600" : "border-transparent"
            } pb-1 border-bottom border-w-2 transition-fast`}
          >
            <span className="text-sm font-weight-semibold">{label.label}</span>
          </div>
        </Button>
      ))}
    </div>
  );
};

export default DropdownToggle;
