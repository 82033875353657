import React, { useContext } from "react";
import { MainContext } from "../../../Context/MainContext";
import { Link, useParams } from "react-router-dom";
import { Col, Button, Form } from "react-bootstrap";
import UilInfoCircle from "@iconscout/react-unicons/icons/uil-info-circle";
import dayjs from "dayjs";

const SpecialDetails = ({ id, special, specialBullets }) => {
  const { triggerModal, setActiveSpecial, gaEvent } = useContext(MainContext);
  const { dealerId } = useParams();
  console.log(special);
  const handleClick = (value) => {
    setActiveSpecial(special);
    gaEvent("contact", "click", "openModal");
    triggerModal(value);
  };

  const setTaxesAndFees = () => {
    if (
      special.plus_acquisition_fee != "1" ||
      special.plus_registration_fee != "1"
    ) {
      if (special.plus_sales_tax != "1") {
        return "+ Taxes & Fees";
      } else {
        return "+ Fees";
      }
    } else if (special.plus_sales_tax != "1") {
      return "+ Taxes";
    }
  };

  const specialLink =
    "/specials/" +
    (dealerId ? `${dealerId}/` : "") +
    `${id}/` +
    `${special.subspecial_id}/` +
    `${special.vehicle.year}/` +
    `${special.vehicle.make}/` +
    `${special.vehicle.model}/` +
    `${special.stock_number}`;

  return (
    <div className="d-flex flex-column flex-grow-1">
      {special.down_payment &&
        special.down_payment > 0 &&
        special.down_payment == 0 && (
          <div className="border-bottom px-2 py-2 text-gray-700 d-flex align-items-center">
            <span className="d-flex ml-2 mr-2">
              <UilInfoCircle size="22" color="currentColor" />
            </span>
            {special.special_type === "apr" ? (
              <span
                className="text-md font-weight-semibold"
                style={{ lineHeight: "1.5" }}
              >
                ${Number(special.down_payment).toLocaleString()} Down Payment{" "}
                {setTaxesAndFees()} on approved credit
                <span
                  className="text-md font-weight-semibold"
                  style={{ lineHeight: "1.5" }}
                ></span>
              </span>
            ) : (
              <span
                className="text-md font-weight-semibold"
                style={{ lineHeight: "1.5" }}
              >
                ${Number(special.down_payment).toLocaleString()} Down Payment{" "}
                {setTaxesAndFees()}
              </span>
            )}
          </div>
        )}
      {special.special_type === "apr" && special.down_payment == 0 && (
        <div className="border-bottom px-2 py-2 text-gray-700 d-flex align-items-center">
          <span className="d-flex ml-2 mr-2">
            <UilInfoCircle size="22" color="currentColor" />
          </span>
          <span
            className="text-md font-weight-semibold"
            style={{ lineHeight: "1.5" }}
          >
            On approved credit, down payment may be required
          </span>
        </div>
      )}
      {special.special_type === "lease" &&
        special.down_payment > 0 &&
        special.security_deposit > 0 && (
          <div className="border-bottom px-2 py-2 text-gray-700 d-flex align-items-center">
            <span className="d-flex ml-2 mr-2">
              <UilInfoCircle size="22" color="currentColor" />
            </span>
            <span
              className="text-md font-weight-semibold"
              style={{ lineHeight: "1.5" }}
            >
              ${Number(special.down_payment).toLocaleString()} Down Payment + $
              {Number(special.security_deposit).toLocaleString()} Security
              Deposit {setTaxesAndFees()}
            </span>
          </div>
        )}
      {special.special_type === "lease" &&
        special.down_payment > 0 &&
        special.security_deposit == 0 && (
          <div className="border-bottom px-2 py-2 text-gray-700 d-flex align-items-center">
            <span className="d-flex ml-2 mr-2">
              <UilInfoCircle size="22" color="currentColor" />
            </span>
            <span
              className="text-md font-weight-semibold"
              style={{ lineHeight: "1.5" }}
            >
              ${Number(special.security_deposit).toLocaleString()} Security
              Deposit {setTaxesAndFees()}
            </span>
          </div>
        )}
      <div className="mb-6 flex-grow-1 px-4 pt-4">
        <ul className="pl-4">
          {specialBullets.filter((i) => i && i.length > 0).slice(0, 3).map((i, k) => (
            <li key={k}>{i}</li>
          ))}
          {special.vehicle.new === false && (
            <li>
              Mileage: {Number(special.vehicle.odometer).toLocaleString()}
            </li>
          )}
          {special?.end_date && (
            <li>
              Special Expiration Date:{" "}
              {dayjs(special?.end_date).format("MMMM DD, YYYY")}
            </li>
          )}
        </ul>
      </div>
      <div className="px-4 pb-4">
        <Form.Row>
          <Col>
            <Button
              variant="blue-500"
              block
              onClick={() => handleClick("contact")}
            >
              <span className="font-weight-bold">Contact Us</span>
            </Button>
          </Col>
          <Col>
            <Link
              onClick={() => gaEvent("moreDetails", "click", specialLink)}
              className="btn btn-outline-blue-500 btn-block"
              to={specialLink}
            >
              <span className="font-weight-bold">More Details</span>
            </Link>
          </Col>
        </Form.Row>
      </div>
    </div>
  );
};

export default SpecialDetails;
