import React, { useContext } from "react";

import { MainContext } from "../../Context/MainContext";

import { Row, Col, Button } from "react-bootstrap";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import { filterTypes } from "../../utils/types";

const ActiveFilters = () => {
  const {
    MainState,
    clearFilters,
    handleCheckboxSelect,
    handleRangeSelect,
    handleSearchFilter,
    handleRemoveDealerFilter,
  } = useContext(MainContext);

  const currentMatchCount = MainState.filteredSpecials.length;
  const currentSearch = MainState.currentSearch;
  const staticDealerships = MainState.staticDealerships;
  const totalSpecials = MainState.staticSpecials.length;

  const handleClearAll = () => {
    handleSearchFilter("");
    clearFilters();
  };

  const activeFilterObjects = Object.keys(MainState.filterSelections).reduce(
    (acc, key) => {
      const filterObj = [];
      if (
        key === filterTypes.PRICE ||
        (key === filterTypes.PAYMENT && MainState.filterSelections[key].length)
      ) {
        if (MainState.filterSelections[key][0])
          filterObj.push({
            key: key,
            label: "min",
            value: `Min ${key}: $${Number(
              MainState.filterSelections[key][0]
            ).toLocaleString()}`,
          });
        if (MainState.filterSelections[key][1])
          filterObj.push({
            key: key,
            label: "max",
            value: `Max ${key}: $${Number(
              MainState.filterSelections[key][1]
            ).toLocaleString()}`,
          });
      } else {
        MainState.filterSelections[key].length &&
          MainState.filterSelections[key].forEach((val) => {
            filterObj.push({
              key: key,
              value: val,
            });
          });
      }
      return acc.concat(filterObj);
    },
    []
  );

  const handleSaleTypeFilter = (value) => {
    return value === "apr"
      ? "APR"
      : value === "buy"
      ? "Buy"
      : value === "finance"
      ? "Finance"
      : "Lease";
  };

  const getDealerName = (value) => {
    return staticDealerships.length
      ? staticDealerships.find((dealer) => dealer.id === value).name
      : "";
  };

  const activeFilters = activeFilterObjects
    .filter((filter) => {
        if (!Object.values(filterTypes).includes(filter.key)) return false;
        if (MainState.noMakes && filter.key === filterTypes.MAKE) return false; 
        return true;
    })
    .map((filter, idx) => {
      return (
        <div
          className="rounded-pill mr-2 bg-blue-500 px-2 py-1 d-flex align-items-center text-white mb-2 text-capitalize"
          key={idx}
        >
          <div className="d-flex mr-1">
            <span className="font-weight-semibold text-xs">
              {filter.key === filterTypes.SALE_TYPE
                ? handleSaleTypeFilter(filter.value)
                : filter.key === filterTypes.DEALER_ID
                ? getDealerName(filter.value)
                : filter.value}
            </span>
          </div>
          <Button
            size="sm"
            variant="link-white"
            className="p-0 shadow-none d-flex border-0"
            onClick={() => {
              if (
                filter.key === filterTypes.PRICE ||
                filter.key === filterTypes.PAYMENT
              ) {
                if (filter.label === "min") {
                  handleRangeSelect(
                    filter.key,
                    "",
                    MainState.filterSelections[filter.key][1]
                  );
                } else {
                  handleRangeSelect(
                    filter.key,
                    MainState.filterSelections[filter.key][0],
                    ""
                  );
                }
              } else if (filter.key === filterTypes.DEALER_ID) {
                handleRemoveDealerFilter();
              } else {
                handleCheckboxSelect(filter.key, filter.value, false);
              }
            }}
          >
            <UilTimes size="18" color="currentColor" />
          </Button>
        </div>
      );
    });

  const ActiveFiltersLabel = () => (
    <>
      {(currentSearch.length > 0 || activeFilters.length > 0) && (
        <div>
          <div>
            <span>
              {currentMatchCount}{" "}
              {currentMatchCount === 1 ? "vehicle" : "vehicles"} (of{" "}
              {totalSpecials}) matching{" "}
            </span>
            {currentSearch.length > 0 && (
              <span>
                "<span className="font-weight-semibold">{currentSearch}</span>"
              </span>
            )}
            {currentSearch && activeFilters.length > 0 && <span> and </span>}
            {activeFilters.length > 0 && (
              <span className="font-weight-semibold">
                {activeFilters.length}{" "}
                {activeFilters.length > 1 ? "filters" : "filter"}
              </span>
            )}
          </div>
          <Button
            variant="link-blue-500"
            size="sm"
            className="text-decoration-none shadow-none p-1"
            onClick={() => handleClearAll()}
          >
            <span className="font-weight-semibold">Clear All</span>
          </Button>
        </div>
      )}
    </>
  );

  return (
    <div>
      <div className="d-lg-none">
        <div>
          <div className="mb-4">
            <div className="d-flex flex-wrap">{activeFilters}</div>
          </div>
          <ActiveFiltersLabel />
        </div>
      </div>
      <div className="d-none d-lg-block">
        <Row>
          <Col xs={"auto"}>
            <ActiveFiltersLabel />
          </Col>
          {activeFilters.length > 0 && (
            <Col>
              <div className="d-flex flex-wrap">{activeFilters}</div>
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default ActiveFilters;
