import React, { useState, useContext } from "react";

import { MainContext } from "../../../Context/MainContext";
import { Button, Collapse, Form } from "react-bootstrap";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import { filterTypes } from "../../../utils/types";

const CheckboxCollapse = ({ label, filter, values }) => {
  const { MainState, handleCheckboxSelect } = useContext(MainContext);

  const [open, setOpen] = useState(false);

  const allSpecials = MainState.staticSpecials;
  const activeFilters = MainState.filterSelections;
  const appliedFilterCount = activeFilters[filter].length;

  const FilterCount = ({ value }) => {
    const tempSpecials = Object.keys(activeFilters).reduce(
      (acc, key) => {
        if (key !== filter) {
          const matches = acc.filter((special) => {
            const currFilter = activeFilters[key];
            let isMatch = null;

            if (currFilter.length) {
              if (key === filterTypes.PRICE) {
                const priceHasValues = activeFilters.price.some((val) => {
                  return val !== "";
                });
                if (priceHasValues) {
                  const price = parseInt(special.sale_price);
                  const type = special.sale_type;
                  isMatch =
                    type === "buy" &&
                    price >= (parseInt(activeFilters.price[0]) || 0) &&
                    price <= (parseInt(activeFilters.price[1]) || Infinity);
                }
              } else if (key === filterTypes.PAYMENT) {
                const paymentHasValues = activeFilters.payment.some((val) => {
                  return val !== "";
                });
                if (paymentHasValues) {
                  const payment = parseInt(special.sale_payment);
                  const type = special.sale_type;
                  isMatch =
                    (type === "lease" || type === "finance") &&
                    payment >= (parseInt(activeFilters.payment[0]) || 0) &&
                    payment <= (parseInt(activeFilters.payment[1]) || Infinity);
                }
              } else if (key === filterTypes.SALE_TYPE) {
                const saleTypes = activeFilters.sale_type.map((type) => {
                  return type.toLowerCase();
                });
                isMatch = saleTypes.includes(special.sale_type.toLowerCase());
              } else if (key === filterTypes.CONDITION) {
                const condition = special.vehicle.new === true ? "new" : "used";
                isMatch = activeFilters.condition.includes(condition);
              } else {
                isMatch = currFilter.includes(special.vehicle[key]);
              }
            }
            return isMatch;
          });
          if (matches.length) {
            acc = matches;
          }
        }
        return acc;
      },
      [...allSpecials]
    );

    if (filter === filterTypes.SALE_TYPE) {
      return tempSpecials.filter((s) => {
        return value.toLowerCase() === s.sale_type.toLowerCase();
      }).length;
    } else if (filter === filterTypes.CONDITION) {
      return tempSpecials.filter((s) => {
        return value === (s.vehicle.new === true ? "new" : "used");
      }).length;
    } else {
      return tempSpecials.filter((s) => {
        return value === s.vehicle[filter];
      }).length;
    }
  };

  const isChecked = (val) => {
    return MainState.filterSelections[filter].includes(val) ? true : false;
  };

  return (
    <div className="border-top">
      <Button
        variant="link-blue-600"
        onClick={() => setOpen(!open)}
        aria-controls={`${filter}-collapse`}
        aria-expanded={open}
        className="border-0 text-left text-decoration-none d-flex align-items-center justify-content-between px-6 py-3"
        block
      >
        <div className="d-flex align-items-center">
          <span className="text-uppercase text-sm font-weight-bold tracking-wider ">
            {label}
          </span>
          {appliedFilterCount > 0 && (
            <span className="bg-blue-600 rounded-pill text-white text-xs ml-2 min-w-4 text-center">
              {appliedFilterCount}
            </span>
          )}
        </div>
        <span
          className="transition-fast"
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <UilAngleDown size="32" color="currentColor" />
        </span>
      </Button>
      <Collapse in={open}>
        <div id={`${filter}-collapse`}>
          <div className="px-6 pt-2 pb-4">
            {values.map((val) => (
              <div className="py-1 mb-1" key={`${filter}-${val.value || val}`}>
                <Form.Check
                  className="w-100"
                  custom
                  type="checkbox"
                  id={`${label}-${val.value || val}`}
                >
                  <Form.Check.Input
                    name={filter}
                    checked={isChecked(val.value || val)}
                    type="checkbox"
                    onChange={(e) =>
                      handleCheckboxSelect(
                        filter,
                        val.value || val,
                        e.target.checked
                      )
                    }
                  />
                  <Form.Check.Label className="w-100">
                    <div className="d-flex align-items-baseline">
                      <span className="font-weight-semibold mr-1 text-nowrap">
                        {val.text || val}
                      </span>
                      <span className="text-blue-600 text-2xs font-weight-semibold">
                        (<FilterCount value={val.value || val} />)
                      </span>
                    </div>
                  </Form.Check.Label>
                </Form.Check>
              </div>
            ))}
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default CheckboxCollapse;
