export const filterTypes = Object.freeze({
  PRICE: "price",
  PAYMENT: "payment",
  BODY: "body",
  YEAR: "year",
  MAKE: "make",
  MODEL: "model",
  LOCATION: "location",
  SALE_TYPE: "sale_type",
  CONDITION: "condition",
  DEALER_ID: "dealer_id",
});
