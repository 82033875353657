import React, { useRef, useState, useContext } from "react";

import { MainContext } from "../../Context/MainContext";
import { Modal, Button, Overlay, Tooltip, Form } from "react-bootstrap";
import SpecialBadge from "../Results/Special/SpecialBadge";
import UilTwitter from "@iconscout/react-unicons/icons/uil-twitter";
import UilShareAlt from "@iconscout/react-unicons/icons/uil-share-alt";
import UilUpload from "@iconscout/react-unicons/icons/uil-upload";
import UilFacebookF from "@iconscout/react-unicons/icons/uil-facebook-f";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilClipboardNotes from "@iconscout/react-unicons/icons/uil-clipboard-notes";

const ShareModal = () => {
  const { MainState, triggerModal, gaEvent } = useContext(MainContext);
  const activeSpecial = MainState.activeSpecial;
  const vehicle = activeSpecial.vehicle;
  const shareUrl = `${window.location.origin}/specials/${activeSpecial.id}/${activeSpecial.subspecial_id}/${vehicle.year}/${vehicle.make}/${vehicle.model}/${vehicle.stock_number}`;
  const isMacOS =
    navigator.platform &&
    /iPad|iPhone|iPod|Macintosh|MacIntel|MacPPC|Mac68K/.test(
      navigator.platform
    );

  const [showTooltip, setShowTooltip] = useState(false);
  const tooltipTarget = useRef(null);

  const shareTwitter = () => {
    gaEvent("share", "click", "twitter");

    const shareText = `Check out this ${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.series}\n`;
    const url = `https://twitter.com/share?text=${shareText}&url=${shareUrl}`;
    const options =
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=436,width=646";
    window.open(url, "Twitter", options);
  };

  const shareFacebook = () => {
    gaEvent("share", "click", "facebook");

    const url = `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`;
    const options =
      "menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=436,width=646";
    window.open(url, "Facebook", options);
  };

  const copyUrl = () => {
    gaEvent("share", "click", "copyLink");

    const input = document.createElement("input");
    document.body.appendChild(input);
    input.value = shareUrl;
    input.select();
    document.execCommand("copy");
    document.body.removeChild(input);
    setShowTooltip(true);
    setTimeout(() => {
      setShowTooltip(false);
    }, 1000);
  };

  const shareWebAPI = () => {
    const shareData = {
      title: "West Herr Specials",
      text: `Check out this ${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.series}`,
      url: shareUrl,
    };

    if (navigator.share) {
      gaEvent("share", "click", "shareAPI");
      navigator.share(shareData);
    } else {
      copyUrl();
    }
  };

  const SharePreview = () => {
    return (
      <div>
        <div className="border border-slate-500 rounded d-flex overflow-hidden shadow-sm">
          <div className="embed-responsive embed-responsive-1by1 w-24 flex-shrink-0">
            {activeSpecial.photos[0] ? (
              <img
                className="embed-responsive-item object-cover"
                src={activeSpecial.photos[0]}
                alt={`${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.series}`}
              />
            ) : (
              <div className="embed-responsive-item bg-blue-800 d-flex align-items-center justify-content-center">
                <div className="h-16 w-16 opacity-10">
                  <img
                    className="h-100 w-100 object-contain"
                    alt=""
                    src="/assets/img/wh-silhouette-white.png"
                  />
                </div>
              </div>
            )}
          </div>
          <div className="px-4 py-2 d-flex flex-grow-1 flex-column justify-content-between text-truncate">
            <div className="d-flex justify-content-between">
              <div className="text-truncate">
                <div className="text-truncate font-weight-semibold text-lg">
                  <span
                    className={
                      vehicle.make.toLowerCase() === "nissan"
                        ? "font-nissan-brand"
                        : vehicle.make.toLowerCase() === "infiniti"
                        ? "font-infiniti-brand"
                        : ""
                    }
                  >
                    {vehicle.year} {vehicle.make} {vehicle.model}{" "}
                    {vehicle.series}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <div className="mr-2 d-flex">
                    <SpecialBadge
                      condition={activeSpecial.vehicle.new}
                      type={activeSpecial.sale_type}
                    />
                  </div>
                  <div>
                    <span className="d-flex text-gray-700 text-sm d-flex">
                      {vehicle.body}
                      {vehicle.drivetrain && ` • ${vehicle.drivetrain}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex text-truncate">
              <span className="text-xs font-italic text-gray-600 text-truncate">
                {shareUrl}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal.Body className="position-relative p-0">
        <div className="p-4">
          <span className="text-lg font-weight-bold">Share this Special</span>
        </div>
        <div className="position-absolute top-0 right-0">
          <Button
            onClick={() => triggerModal()}
            variant="link-gray-700"
            className="h-12 w-12 p-0 d-flex align-items-center justify-content-center transition-none"
          >
            <UilTimes color="currentColor" />
          </Button>
        </div>

        <div className="border-bottom px-4 pt-4 pb-6">
          <SharePreview />
        </div>
        <div className="py-4 px-8">
          <div className="d-flex mb-4 justify-content-center">
            <button
              onClick={() => shareTwitter()}
              className="border-0 p-4 bg-transparent"
            >
              <div className="w-20 embed-responsive embed-responsive-1by1 mb-2">
                <div className="embed-responsive-item rounded-circle bg-blue-50 text-blue-600 d-flex align-items-center justify-content-center">
                  <div className="opacity-100">
                    <UilTwitter size="32" color="currentColor" />
                  </div>
                </div>
              </div>
              <div>
                <span className="text-uppercase text-blue-600 text-sm tracking-wider font-weight-bold">
                  Tweet
                </span>
              </div>
            </button>
            <button
              onClick={() => shareFacebook()}
              className="border-0 p-4 ml-2 bg-transparent"
            >
              <div className="w-20 embed-responsive embed-responsive-1by1 mb-2">
                <div className="embed-responsive-item rounded-circle bg-blue-50 text-blue-600 d-flex align-items-center justify-content-center">
                  <UilFacebookF size="32" color="currentColor" />
                </div>
              </div>
              <div>
                <span className="text-uppercase text-blue-600 text-sm tracking-wider font-weight-bold">
                  Post
                </span>
              </div>
            </button>
            {navigator.share && (
              <button
                onClick={() => shareWebAPI()}
                className="border-0 p-4 ml-2 bg-transparent"
              >
                <div className="w-20 embed-responsive embed-responsive-1by1 mb-2">
                  <div className="embed-responsive-item rounded-circle bg-blue-50 text-blue-600 d-flex align-items-center justify-content-center">
                    {isMacOS ? (
                      <UilUpload size="32" color="currentColor" />
                    ) : (
                      <UilShareAlt size="32" color="currentColor" />
                    )}
                  </div>
                </div>
                <div>
                  <span className="text-uppercase text-blue-600 text-sm tracking-wider font-weight-bold">
                    Share
                  </span>
                </div>
              </button>
            )}
          </div>
          <div>
            <Form.Group className="mb-3">
              <Form.Label className="text-uppercase text-sm tracking-wider font-weight-bold text-blue-600">
                Page Link
              </Form.Label>
              <Form.Control
                aria-label="Special URL"
                className="bg-slate-100 border-slate-300 text-slate-700"
                value={shareUrl}
                readOnly
              />
            </Form.Group>
            <div>
              <Button
                ref={tooltipTarget}
                variant="link"
                className="text-decoration-none text-blue-500 d-flex align-items-center p-2 shadow-none mx-auto"
                onClick={() => copyUrl()}
              >
                <span className="d-flex mr-1">
                  <UilClipboardNotes size="22" color="currentColor" />
                </span>
                <span className="text-uppercase font-weight-bold text-sm tracking-wide">
                  Copy Url
                </span>
              </Button>
              <Overlay
                target={tooltipTarget.current}
                show={showTooltip}
                placement="bottom"
              >
                {(props) => <Tooltip {...props}>Link Copied!</Tooltip>}
              </Overlay>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  );
};

export default ShareModal;
