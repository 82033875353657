import React, { useState, useEffect } from "react";

const SwiperSlide = ({ image }) => {
  const [isReady, setReady] = useState(false);

  useEffect(() => {
    setReady(false);
  }, [image]);

  return (
    <div className="embed-responsive embed-responsive-4by3">
      <div className="embed-responsive-item bg-dark">
        <img
          style={{ opacity: isReady ? "1" : "0" }}
          onLoad={() => setReady(true)}
          className="h-100 w-100 object-cover transition-fast"
          src={image}
          alt=""
        />
      </div>
    </div>
  );
};

export default SwiperSlide;
