import React, { useContext, useState, useRef, useEffect } from "react";

import { MainContext } from "../../Context/MainContext";
import { Button, Collapse, Form } from "react-bootstrap";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import UilSortAmount from "@iconscout/react-unicons/icons/uil-sort-amount-down";
import UilSlidersV from "@iconscout/react-unicons/icons/uil-sliders-v";
import { filterTypes } from "../../utils/types";

const MobileFilterContainer = ({ className }) => {
  const { MainState, triggerModal, handleSearchFilter } = useContext(
    MainContext
  );
  const [searchIsOpen, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const searchRef = useRef(null);

  const handleSubmit = (e) => {
    e.preventDefault();
    setOpen(false);
    handleSearchFilter(searchValue);
  };

  useEffect(() => {
    setSearchValue(MainState.currentSearch);
  }, [MainState.currentSearch]);

  const handleFocus = () => {
    searchRef.current.focus();
  };

  const activeFilterObjects = Object.keys(MainState.filterSelections).reduce(
    (acc, key) => {
      let filterObj = [];
      if (
        key === filterTypes.PRICE ||
        (key === filterTypes.PAYMENT && MainState.filterSelections[key].length)
      ) {
        if (MainState.filterSelections[key][0])
          filterObj.push({
            key: key,
            label: "min",
            value: `Min ${key}: $${Number(
              MainState.filterSelections[key][0]
            ).toLocaleString()}`,
          });
        if (MainState.filterSelections[key][1])
          filterObj.push({
            key: key,
            label: "max",
            value: `Max ${key}: $${Number(
              MainState.filterSelections[key][1]
            ).toLocaleString()}`,
          });
      } else {
        MainState.filterSelections[key].length &&
          MainState.filterSelections[key].forEach((val) => {
            filterObj.push({
              filter: key,
              value: val,
            });
          });
      }
      return acc.concat(filterObj);
    },
    []
  );

  return (
    <div className={`bg-white shadow ${className}`}>
      <div className="d-flex">
        <div
          className={`flex-grow-1 border-right h-12 transition-fast ${
            searchIsOpen ? "bg-slate-200" : ""
          }`}
        >
          <button
            onClick={() => setOpen(!searchIsOpen)}
            aria-controls="searchCollapse"
            aria-expanded={searchIsOpen}
            className="w-100 h-100 bg-transparent border-0 d-flex align-items-center justify-content-center"
          >
            <span className="d-inline-flex">
              <UilSearch size="20" color="currentColor" className="mr-2" />
            </span>
            <span className="font-weight-semibold">Search</span>
          </button>
        </div>
        <div className="flex-grow-1 border-right h-12">
          <button
            onClick={() => triggerModal("mobileFilters")}
            className="w-100 h-100 text-center bg-transparent border-0 d-flex align-items-center justify-content-center"
          >
            <span className="d-inline-flex">
              <UilSlidersV size="20" color="currentColor" className="mr-2" />
            </span>
            <span className="font-weight-semibold">Filters</span>
            {activeFilterObjects.length > 0 && (
              <span className="bg-blue-600 rounded-pill text-white text-xs ml-2 min-w-4 text-center">
                {activeFilterObjects.length}
              </span>
            )}
          </button>
        </div>
        <div className="flex-grow-1 h-12">
          <button
            onClick={() => triggerModal("mobileSort")}
            className="w-100 h-100 text-center bg-transparent border-0 d-flex align-items-center justify-content-center"
          >
            <span className="d-inline-flex">
              <UilSortAmount size="20" color="currentColor" className="mr-2" />
            </span>
            <span className="font-weight-semibold">Sort</span>
          </button>
        </div>
      </div>
      <div>
        <Collapse in={searchIsOpen} onEntering={() => handleFocus()}>
          <div>
            <div className="border-top p-4" id="searchCollapse">
              <div className="position-relative">
                <Form className="d-flex" onSubmit={handleSubmit}>
                  <Form.Control
                    type="search"
                    ref={searchRef}
                    onChange={(e) => {
                      setSearchValue(e.target.value);
                    }}
                    value={searchValue}
                    placeholder="Search by year, make, model..."
                    className="pl-10 border-slate-500 mr-2"
                  />
                  <Button
                    type="submit"
                    variant="blue-500"
                    className="py-0 align-items-center justify-content-center"
                  >
                    <span className="d-flex">
                      <UilSearch size="20" color="currentColor" />
                    </span>
                  </Button>
                </Form>
                <div className="position-absolute w-10 h-100 text-slate-500 d-flex align-items-center justify-content-center top-0 pl-2">
                  <UilSearch size="20" color="currentColor" className="mr-2" />
                </div>
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

export default MobileFilterContainer;
