import React, { useState, useContext, useEffect } from "react";
import { Button, Collapse, Form } from "react-bootstrap";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";

import { MainContext } from "../../../Context/MainContext";
import DropdownToggle from "../DropdownToggle";

const RangeCollapse = ({ label }) => {
  const [open, setOpen] = useState(false);
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const { MainState, handleRangeSelect } = useContext(MainContext);
  const [activeToggle, setActiveToggle] = useState("price");

  function handleSubmit(e, filter) {
    e.preventDefault();
    handleRangeSelect(filter, min, max);
  }

  useEffect(() => {
    const filters = MainState.filterSelections;
    setMin(filters[activeToggle][0]);
    setMax(filters[activeToggle][1]);
  }, [
    JSON.stringify([MainState.filterSelections[activeToggle], activeToggle]),
  ]);

  return (
    <div className="border-top">
      <Button
        variant="link-blue-600"
        onClick={() => setOpen(!open)}
        aria-controls="collapse-price"
        aria-expanded={open}
        className="border-0 text-left text-decoration-none  d-flex align-items-center justify-content-between px-6 py-3"
        block
      >
        <span className="text-uppercase text-sm font-weight-bold tracking-wider ">
          {label}
        </span>
        <span
          className="transition-fast"
          style={{ transform: open ? "rotate(180deg)" : "rotate(0deg)" }}
        >
          <UilAngleDown size="32" color="currentColor" />
        </span>
      </Button>
      <Collapse in={open}>
        <div id="collapse-price">
          <div className="border-bottom px-6 pt-2 d-flex">
            <DropdownToggle
              labels={[
                { label: "Deal Price", value: "price" },
                { label: "Payment", value: "payment" },
              ]}
              handleToggle={setActiveToggle}
            />
          </div>
          <div className="py-2">
            <Form
              onSubmit={(e) => handleSubmit(e, activeToggle)}
              autoComplete="off"
            >
              <div className="d-flex align-items-end pt-2 pb-4 px-6">
                <Form.Group
                  controlId="priceRangeMin"
                  className="mb-0 flex-grow-1"
                >
                  <Form.Label className="mb-0 text-uppercase text-sm tracking-wider font-weight-bold text-blue-600">
                    Min
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    onChange={(e) => setMin(e.target.value)}
                    value={min || ""}
                  />
                </Form.Group>
                <div className="mb-2 flex-shrink-0">
                  <span className="px-2 font-weight-bold">-</span>
                </div>
                <Form.Group
                  controlId="priceRangeMax"
                  className="mb-0 flex-grow-1"
                >
                  <Form.Label className="mb-0 text-uppercase text-sm tracking-wider font-weight-bold text-blue-600">
                    Max
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    type="number"
                    onChange={(e) => setMax(e.target.value)}
                    value={max || ""}
                  />
                </Form.Group>
                <div className="flex-shrink-0 ml-2">
                  <Button type="submit" variant="blue-500">
                    Apply
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </Collapse>
    </div>
  );
};

export default RangeCollapse;
