import React, { useState, useEffect } from "react";

import { useLocation, Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import SpecialBadge from "../Results/Special/SpecialBadge";
import Price from "../Results/Special/Price";

import useWindowSize from "../../Hooks/UseWindowSize";

const RelatedCarousel = ({ specials, current }) => {
  const [swiper, setSwiper] = useState(null);

  const windowWidth = useWindowSize().width;

  const location = useLocation();
  useEffect(() => {
    if (swiper !== null) {
      swiper.slideTo(0);
    }
  }, [location, swiper]);

  useEffect(() => {
    if (swiper !== null) {
      const offset = windowWidth < 992 ? 0 : (8.333 / 100) * windowWidth;

      swiper.params.slidesOffsetBefore = offset;
      swiper.params.slidesOffsetAfter = offset;
      swiper.update();
    }
  }, [windowWidth, swiper]);

  const params = {
    spaceBetween: 0,
    slidesPerView: 1.125,
    breakpoints: {
      576: {
        slidesPerView: 1.5,
      },
      768: {
        slidesPerView: 1.75,
      },
      992: {
        slidesPerView: 2.5,
      },
    },
  };

  return (
    <div className="px-2">
      <Swiper className="px-2 mx-n2" onSwiper={setSwiper} {...params}>
        {specials.map((special, idx) => (
          <SwiperSlide key={idx}>
            <div className="px-2 pb-4">
              <div
                className={`shadow-sm rounded transition-fast ${
                  current === special.subspecial_id
                    ? "bg-blue-50 border-blue-300 border"
                    : "bg-white"
                }`}
                key={special.special_type}
              >
                <div className="px-4 pt-3 pb-2 border-bottom d-flex">
                  <div className="flex-grow-1 text-truncate">
                    <span
                      className={`text-lg font-weight-semibold text-truncate ${
                        special.vehicle.make.toLowerCase() === "nissan"
                          ? "font-nissan-brand"
                          : special.vehicle.make.toLowerCase() === "infiniti"
                          ? "font-infiniti-brand"
                          : ""
                      }`}
                    >
                      {special.vehicle.year} {special.vehicle.make}{" "}
                      {special.vehicle.model} {special.vehicle.series}
                    </span>
                    <div className="d-flex align-items-center flex-wrap">
                      <div className="mr-2">
                        <span className="d-flex text-gray-700 text-sm">
                          {special.vehicle.body}
                          {special.vehicle.drivetrain &&
                            ` • ${special.vehicle.drivetrain}`}
                        </span>
                      </div>
                      <div>
                        <SpecialBadge type={special.special_type} />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-shrink-0">
                    <Link
                      className="btn btn-outline-blue-500 btn-sm font-weight-bold"
                      to={`/specials/${special.id}/${special.subspecial_id}/${special.vehicle.year}/${special.vehicle.make}/${special.vehicle.model}/${special.stock_number}`}
                    >
                      More Details
                    </Link>
                  </div>
                </div>
                <Price
                  leftColClass="py-2"
                  rightColClass="py-2 border-left"
                  className="px-4"
                  special={special}
                />
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default RelatedCarousel;
