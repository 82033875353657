import React, { useContext, useEffect } from "react";
import { MainContext } from "../Context/MainContext";
import { useLocation, useParams } from "react-router-dom";

import GlobalNav from "../Components/Global/GlobalNav";
import DesktopFilterContainer from "../Components/Filter/DesktopFilterContainer";
import MobileFilterContainer from "../Components/Filter/MobileFilterContainer";
import ResultsContainer from "../Components/Results/ResultsContainer";
import ModalContainer from "../Components/Modal/ModalContainer";

const Main = () => {
  const { getSpecials, getQueries, gaPageview, setOnlyMakes } = useContext(MainContext);
  const { pathname, search } = useLocation();
  const { dealerId, make } = useParams();

  useEffect(() => {
    gaPageview(pathname);
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    getQueries(search);
    getSpecials(dealerId ?? "");
  }, [dealerId]);

  useEffect(() => {
    if (make) {
        setOnlyMakes(make);
    }
  }, [make]);

  return (
    <div>
      <GlobalNav />
      <ResultsContainer>
        <div className="border-top border-blue-500 border-w-2 sticky-top mb-4 mb-lg-8">
          <MobileFilterContainer className="d-lg-none" />
          <DesktopFilterContainer className="d-none d-lg-block" />
        </div>
      </ResultsContainer>
      <ModalContainer />
      <div dangerouslySetInnerHTML={{ __html: "<!-- 1/28/22 -->" }} />
    </div>
  );
};

export default Main;
