import React, { useContext } from "react";

import { MainContext } from "../../../Context/MainContext";
import { Pagination } from "react-bootstrap";

const Pages = () => {
  const { MainState, handlePageChange, gaEvent } = useContext(MainContext);

  const handleClick = (pageNumber, type) => {
    if (currentPage === pageNumber) return;
    gaEvent("pagination", type, pageNumber.toString());
    handlePageChange(pageNumber);
    window.scrollTo(0, 0);
  };

  const totalRows = MainState.filteredSpecials.length;
  const totalPages = Math.ceil(totalRows / MainState.perPage);
  const currentPage = MainState.currentPage;

  const LEFT_PAGE = "LEFT";
  const RIGHT_PAGE = "RIGHT";
  const ELLIPSIS = "ELLIPSIS";
  /**
   * Helper method for creating a range of numbers
   * range(1, 5) => [1, 2, 3, 4, 5]
   */
  const range = (from, to, step = 1) => {
    let i = from;
    const range = [];

    while (i <= to) {
      range.push(i);
      i += step;
    }

    return range;
  };

  function fetchPageNumbers() {
    const pageNeighbours = 1;
    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;

    if (totalPages > totalBlocks) {
      const startPage = Math.max(1, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages, currentPage + pageNeighbours);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: < {...} {6} [7] {8}
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [ELLIPSIS, ...extraPages, ...pages];
          break;
        }

        // handle: {3} [4] {5} {...} >
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, ELLIPSIS];
          break;
        }

        // handle: < {...} {5} [6] {7} {...} >
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [ELLIPSIS, ...pages, ELLIPSIS];
          break;
        }
      }

      return [LEFT_PAGE, ...pages, RIGHT_PAGE];
    }
    return range(1, totalPages);
  }

  const pages = fetchPageNumbers().map((page, idx) => {
    if (page === LEFT_PAGE)
      return (
        <Pagination.Prev
          key={idx}
          onClick={() => handleClick(currentPage - 1, "prev")}
          disabled={currentPage === 1}
        />
      );

    if (page === RIGHT_PAGE)
      return (
        <Pagination.Next
          key={idx}
          onClick={() => handleClick(currentPage + 1, "next")}
          disabled={currentPage === totalPages}
        />
      );

    if (page === ELLIPSIS) return <Pagination.Ellipsis key={idx} disabled />;

    return (
      <Pagination.Item
        key={idx}
        onClick={() => handleClick(page, "jump")}
        active={page === currentPage}
        className="font-weight-semibold"
      >
        {page}
      </Pagination.Item>
    );
  });

  return (
    totalPages > 1 && (
      <div>
        <Pagination className="shadow-sm mb-0">{pages}</Pagination>
      </div>
    )
  );
};

export default Pages;
