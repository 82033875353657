import React, { useContext, useState, useEffect } from "react";

import { MainContext } from "../../Context/MainContext";
import Pagination from "./Pagination/Pages";
import { Container, Row, Col, Form, Spinner, Button } from "react-bootstrap";

import SpecialContainer from "./Special/SpecialContainer";
import DesktopSort from "../Sort/DesktopSortContainer";
import ActiveFilters from "../Filter/ActiveFilters";

import blob from "../../assets/img/blob.svg";
import UilSearch from "@iconscout/react-unicons/icons/uil-search";
import UilCalender from "@iconscout/react-unicons/icons/uil-calender";

const ResultsContainer = ({ className, children }) => {
  const { MainState, handleSearchFilter, clearFilters, gaEvent } = useContext(
    MainContext
  );
  const [searchValue, setSearchValue] = useState("");

  const perPage = MainState.perPage;
  const showing = MainState.currentPage * perPage;
  const totalRows = MainState.filteredSpecials.length;
  const range = {
    start: showing - perPage + 1,
    end: showing < totalRows ? showing : totalRows,
  };
  const currentSearch = MainState.currentSearch;

  const hasFilters = Object.values(MainState.filterSelections).some(
    (val) => val.length
  );

  const visible = MainState.filteredSpecials.slice(range.start - 1, range.end);
  const anySpecials = MainState.staticSpecials.length > 0;

  const showingRange = totalRows ? (
    <span>
      Showing {range.start} - {range.end} of {totalRows}
    </span>
  ) : null;

  const specials = visible.map((item, key) => (
    <Col md="6" lg="4" key={key} className="mb-8">
      <SpecialContainer special={item} />
    </Col>
  ));

  const handleSubmit = (e) => {
    e.preventDefault();
    handleSearchFilter(searchValue);
  };

  useEffect(() => {
    setSearchValue(currentSearch);
  }, [currentSearch]);

  useEffect(() => {
    if (!specials.length && currentSearch) {
      const valueArr = currentSearch ? currentSearch.split(" ") : [];
      gaEvent("search", "no results", valueArr.join());
    }
  }, [specials, currentSearch]);

  const handleClearAll = () => {
    handleSearchFilter("");
    clearFilters();
  };

  const noResults = anySpecials ? (
    <div className="text-center">
      <div className="position-relative w-32 h-32 mx-auto mb-4 d-flex align-items-center justify-content-center">
        <img className="max-w-100 max-h-100 w-auto h-auto" src={blob} alt="" />
        <div className="position-absolute h-100 w-100  d-flex align-items-center justify-content-center top-0">
          <UilSearch size="60" color="white" />
        </div>
      </div>

      <div className="mb-12 mb-lg-0">
        <h2 className="text-3xl text-lg-4xl font-weight-semibold mb-4">
          Sorry, there's nothing that matches those filters!
        </h2>
        <p className="text-gray-800 text-lg text-lg-xl">
          Try to search for something else,{" "}
          <br className="d-none d-lg-inline" /> change your filters, or check
          for spelling errors.
        </p>
        <Button
          variant="link-blue-500"
          className="text-decoration-none shadow-none p-0 d-none d-lg-inline font-weight-semibold"
          onClick={() => handleClearAll()}
        >
          Clear Your Filters
        </Button>
      </div>
      <div className="d-lg-none">
        <div className="position-relative mb-6">
          <Form className="d-flex" onSubmit={handleSubmit}>
            <Form.Control
              type="search"
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              value={searchValue}
              placeholder="Search by year, make, model..."
              className="pl-10 border-slate-500 mr-2"
            />
            <Button
              type="submit"
              variant="blue-500"
              className="py-0 align-items-center justify-content-center"
            >
              <span className="d-flex">
                <UilSearch size="20" color="currentColor" />
              </span>
            </Button>
          </Form>
          <div className="position-absolute w-10 h-100 text-slate-500 d-flex align-items-center justify-content-center top-0 pl-2">
            <UilSearch size="20" color="currentColor" className="mr-2" />
          </div>
        </div>
        <div>
          <Button
            variant="link-gray-800"
            className="text-decoration-none shadow-none p-0 d-inline"
            onClick={() => handleClearAll()}
          >
            Or{" "}
            <span className="text-blue-500 font-weight-semibold">
              clear your filters
            </span>
            .
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <div className="text-center">
      <div className="position-relative w-32 h-32 mx-auto mb-4 d-flex align-items-center justify-content-center">
        <img className="max-w-100 max-h-100 w-auto h-auto" src={blob} alt="" />
        <div className="position-absolute h-100 w-100  d-flex align-items-center justify-content-center top-0">
          <UilCalender size="60" color="white" />
        </div>
      </div>

      <div className="mb-12 mb-lg-0">
        <h2 className="text-3xl text-lg-4xl font-weight-semibold mb-4">
           Sorry, nothing to show, check back soon!
        </h2>
      </div>
    </div>
  );

  return (
    <div className={className}>
      {children}
      <Container fluid="xl">
        {hasFilters && (
          <Row className="mb-4">
            <Col>
              <ActiveFilters />
            </Col>
          </Row>
        )}
        <Row className="mb-2 mb-lg-8 d-none d-lg-flex">
          {specials.length > 0 && (
            <Col className="justify-content-between align-items-center d-flex">
              <div>{showingRange}</div>
              <DesktopSort />
            </Col>
          )}
        </Row>
        <Row>
          {!MainState.getSpecialsLoading ? (
            specials.length > 0 ? (
              specials
            ) : (
              <Col
                sm={{ span: 10, offset: 1 }}
                md={{ span: 8, offset: 2 }}
                lg={{ span: 6, offset: 3 }}
              >
                {noResults}
              </Col>
            )
          ) : (
            <>
              <Col className="text-center">
                <Spinner variant="blue-500" animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
              </Col>
            </>
          )}
        </Row>
        <Row className="mb-8">
          <Col>
            <div className="d-flex justify-content-between align-items-center">
              <div>{showingRange}</div>
              <div>
                <Pagination />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ResultsContainer;
