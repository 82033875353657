import React, { useState, useContext, useEffect } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";

import { MainContext } from "../../../Context/MainContext";
import DropdownToggle from "../DropdownToggle";

const RangeDropdown = ({ label }) => {
  const [min, setMin] = useState("");
  const [max, setMax] = useState("");
  const { MainState, handleRangeSelect } = useContext(MainContext);
  const [activeToggle, setActiveToggle] = useState("price");

  const handleSubmit = (e, filter) => {
    e.preventDefault();
    handleRangeSelect(filter, min, max);
  };

  useEffect(() => {
    const filters = MainState.filterSelections;
    setMin(filters[activeToggle][0]);
    setMax(filters[activeToggle][1]);
  }, [
    JSON.stringify([MainState.filterSelections[activeToggle], activeToggle]),
  ]);

  return (
    <Dropdown>
      <Dropdown.Toggle
        // This bsPrefix removes the .dropdown-toggle class that shows the default caret
        bsPrefix=":"
        className="text-decoration-none d-flex align-items-center"
        variant="link-blue-600"
        id="price-dropdown"
      >
        <span>{label}</span>
        <span>
          <UilAngleDown size="25" color="currentColor" />
        </span>
      </Dropdown.Toggle>

      <Dropdown.Menu className="border-0 shadow-lg">
        <div className="border-bottom px-4 d-flex">
          <DropdownToggle
            labels={[
              { label: "Deal Price", value: "price" },
              { label: "Payment", value: "payment" },
            ]}
            handleToggle={setActiveToggle}
          />
        </div>
        <div className="px-4 pb-1 pt-2">
          <Form
            onSubmit={(e) => handleSubmit(e, activeToggle)}
            autoComplete="off"
          >
            <div className="d-flex align-items-end">
              <Form.Group controlId="priceRangeMin" className="mb-0">
                <Form.Label className="mb-0 text-uppercase text-sm tracking-wider font-weight-bold text-blue-600">
                  Min
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  className="w-20"
                  type="number"
                  onChange={(e) => setMin(e.target.value)}
                  value={min || ""}
                />
              </Form.Group>
              <div className="mb-2">
                <span className="px-2 font-weight-bold">-</span>
              </div>
              <Form.Group controlId="priceRangeMax" className="mb-0">
                <Form.Label className="mb-0 text-uppercase text-sm tracking-wider font-weight-bold text-blue-600">
                  Max
                </Form.Label>
                <Form.Control
                  autoComplete="off"
                  className="w-20"
                  type="number"
                  onChange={(e) => setMax(e.target.value)}
                  value={max || ""}
                />
              </Form.Group>
            </div>
            <div>
              <Button
                type="submit"
                variant="link"
                size="sm"
                className="shadow-none px-0 text-decoration-none"
              >
                Apply
              </Button>
            </div>
          </Form>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default RangeDropdown;
