import React, { useState, useContext, useEffect } from "react";
import { MainContext } from "../../../Context/MainContext";

import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import CarouselSlide from "./CarouselSlide";
import UilAngleRight from "@iconscout/react-unicons/icons/uil-angle-right";
import UilAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";

SwiperCore.use([Pagination, Navigation]);

const ImageCarousel = ({ images }) => {
  const { MainState, gaEvent } = useContext(MainContext);

  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper !== null) {
      swiper.update();
      swiper.slideTo(0, 0);
    }
  }, [JSON.stringify(MainState.filteredSpecials) + MainState.currentPage]);

  const params = {
    pagination: {
      el: ".swiper-pagination",
      type: "bullets",
      dynamicBullets: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    spaceBetween: 0,
  };

  const goNext = () => {
    if (swiper !== null) {
      swiper.slideNext();
    }
  };

  const goPrev = () => {
    if (swiper !== null) {
      swiper.slidePrev();
    }
  };

  const PrevButton = () => {
    return (
      <div className="d-none d-md-block pb-4 position-absolute h-100 top-0 left-0 z-1 w-14 swiper-button-prev">
        <button
          onClick={() => goPrev()}
          className="h-100 w-100 align-items-center d-flex border-0 p-0"
          style={{
            background:
              "linear-gradient(to left, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0.4) 85%, rgba(0,0,0,0.5) 100%",
          }}
        >
          <div className="text-white ml-n1">
            <UilAngleLeft size="40" fill="currentColor" />
          </div>
        </button>
      </div>
    );
  };

  const NextButton = () => {
    return (
      <div className="d-none d-md-block pb-4 position-absolute h-100 top-0 right-0 z-1 w-14 swiper-button-next">
        <button
          onClick={() => goNext()}
          className="h-100 w-100 align-items-center d-flex justify-content-end border-0 p-0"
          style={{
            background:
              "linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0) 10%, rgba(0,0,0,0.4) 85%, rgba(0,0,0,0.5) 100%",
          }}
        >
          <div className="text-white mr-n1">
            <UilAngleRight size="40" fill="currentColor" />
          </div>
        </button>
      </div>
    );
  };

  const handleSlideChange = ({ activeIndex, previousIndex }) => {
    var dir = activeIndex > previousIndex ? "next" : "prev";
    gaEvent("swiper", dir, activeIndex.toString());
  };

  return (
    <Swiper
      className="pb-4"
      onSwiper={setSwiper}
      onSlideChange={(swiper) => handleSlideChange(swiper)}
      {...params}
    >
      {images.map((img, idx) => (
        <SwiperSlide key={idx}>
          <CarouselSlide image={img} />
        </SwiperSlide>
      ))}
      <PrevButton />
      <NextButton />
      <div className="swiper-pagination bottom-0"></div>
    </Swiper>
  );
};

export default ImageCarousel;
