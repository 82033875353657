import React, { useContext } from "react";

import { Modal } from "react-bootstrap";
import { MainContext } from "../../Context/MainContext";

import ContactModal from "./ContactModal";
import ShareModal from "./ShareModal";
import MobileFiltersModal from "./MobileFiltersModal";
import MobileSortModal from "./MobileSortModal";

const DisplayContent = () => {
  const { MainState } = useContext(MainContext);
  if (MainState.modalContent === "contact") {
    return <ContactModal />;
  } else if (MainState.modalContent === "share") {
    return <ShareModal />;
  } else if (MainState.modalContent === "mobileFilters") {
    return <MobileFiltersModal />;
  } else if (MainState.modalContent === "mobileSort") {
    return <MobileSortModal />;
  } else {
    return null;
  }
};

const ModalContainer = () => {
  const { MainState, triggerModal } = useContext(MainContext);

  return (
    <Modal show={MainState.modalShow} onHide={triggerModal}>
      <DisplayContent />
    </Modal>
  );
};

export default ModalContainer;
