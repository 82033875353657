import React, { useContext, useEffect, useState } from "react";
import { MainContext } from "../Context/MainContext";
import { useLocation, Link, useParams } from "react-router-dom";

import { Container, Row, Col, ListGroup } from "react-bootstrap";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import GlobalNav from "../Components/Global/GlobalNav";
import ImageCarousel from "../Components/Results/Special/ImageCarousel";
import Details from "../Components/Landing/Details";
import ModalContainer from "../Components/Modal/ModalContainer";
import SpecialBadge from "../Components/Results/Special/SpecialBadge";
import Price from "../Components/Results/Special/Price";
import RelatedCarousel from "../Components/Landing/RelatedCarousel";

import UilAngleLeft from "@iconscout/react-unicons/icons/uil-angle-left";

const LandingPage = () => {
  const {
    MainState,
    getSpecials,
    getSpecialById,
    getRelatedSpecials,
    gaPageview,
  } = useContext(MainContext);
  const { id, subId, dealerId } = useParams();
  const [special, setSpecial] = useState({});
  const [relatedSpecials, setRelatedSpecials] = useState([]);

  const location = useLocation();
  useEffect(() => {
    gaPageview(location.pathname);
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    getSpecials(dealerId ?? "");
  }, [dealerId]);

  useEffect(() => {
    if (MainState.staticSpecials.length) {
      setSpecial(getSpecialById(id, subId));
      setRelatedSpecials(getRelatedSpecials(id, subId));
    }
  }, [MainState.staticSpecials, subId]);

  if (Object.keys(special).length) {
    const photos =
      special.photos.length && special.photos[0].length ? (
        <ImageCarousel images={special.photos} />
      ) : (
        <div className="pb-4">
          <div className="embed-responsive embed-responsive-4by3">
            <div className="embed-responsive-item bg-blue-800 d-flex align-items-center justify-content-center">
              <div className="h-32 w-32 opacity-10">
                <img
                  className="h-100 w-100 object-contain"
                  alt=""
                  src="/assets/img/wh-silhouette-white.png"
                />
              </div>
            </div>
          </div>
        </div>
      );

    return (
      <>
        <div className="shadow-sm">
          <GlobalNav />
        </div>
        <Container fluid="xl">
          <Row>
            <Col
              xs={12}
              lg={{ offset: 1, span: 10 }}
              xl={{ offset: relatedSpecials.length > 1 ? 0 : 2, span: 8 }}
            >
              <div className="mt-8 mb-6">
                <Link
                  to={`/specials/${dealerId ? dealerId : ""}`}
                  className="font-weight-semibold text-sm"
                >
                  <span>
                    <UilAngleLeft size="24" fill="currentColor" />
                  </span>
                  <span>Back to Special Listings</span>
                </Link>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              xs={12}
              lg={{ offset: 1, span: 10 }}
              xl={{ offset: relatedSpecials.length > 1 ? 0 : 2, span: 8 }}
            >
              <SwitchTransition mode={"out-in"}>
                <CSSTransition
                  key={special.subspecial_id}
                  addEndListener={(node, done) =>
                    node.addEventListener("transitionend", done, false)
                  }
                  classNames="fade"
                >
                  <div className="bg-white shadow-sm rounded mb-8 pt-0 pb-4 pt-md-10 pb-md-10 overflow-hidden">
                    <div className="mb-2 mb-md-8 px-md-10">{photos}</div>
                    <div className="px-4 px-md-10">
                      <Details special={special} />
                    </div>
                  </div>
                </CSSTransition>
              </SwitchTransition>
            </Col>
            {relatedSpecials.length > 1 && (
              <Col className="d-none d-xl-block">
                <div className="position-sticky top-8">
                  <h2 className="text-2xl mb-2">Related Specials</h2>
                  <div>
                    <ListGroup>
                      {relatedSpecials.map((rel) => (
                        <Link
                          key={rel.special_type}
                          className={`list-group-item list-group-item-action p-0 transition-fast shadow-sm rounded mb-2 text-black border ${
                            subId === rel.subspecial_id
                              ? "bg-blue-50 border-blue-300"
                              : "bg-white border-transparent"
                          }
                          `}
                          to={`/specials/${special.id}/${rel.subspecial_id}/${special.vehicle.year}/${special.vehicle.make}/${special.vehicle.model}/${special.stock_number}`}
                        >
                          <div
                            className={`px-4 pt-3 pb-2 border-bottom d-flex ${
                              subId === rel.subspecial_id
                                ? "border-blue-300"
                                : ""
                            }`}
                          >
                            <div>
                              <span
                                className={`text-lg font-weight-semibold ${
                                  special.vehicle.make.toLowerCase() ===
                                  "nissan"
                                    ? "font-nissan-brand"
                                    : ""
                                }`}
                              >
                                {rel.vehicle.year} {rel.vehicle.make}{" "}
                                {rel.vehicle.model} {rel.vehicle.series}
                              </span>
                              <div className="d-flex align-items-center flex-wrap">
                                <div className="mr-2">
                                  <span className="d-flex text-gray-700 text-sm">
                                    {rel.vehicle.body}
                                    {rel.vehicle.drivetrain &&
                                      ` • ${rel.vehicle.drivetrain}`}
                                  </span>
                                </div>
                                <div>
                                  <SpecialBadge type={rel.special_type} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <Price
                            leftColClass={`py-2 ${
                              subId === rel.subspecial_id
                                ? "border-blue-300"
                                : ""
                            }`}
                            rightColClass="py-2 border-left"
                            className="px-4"
                            special={rel}
                          />
                        </Link>
                      ))}
                    </ListGroup>
                  </div>
                </div>
              </Col>
            )}
          </Row>
        </Container>
        {relatedSpecials.length > 1 && (
          <div className="d-xl-none">
            <Container fluid>
              <Row>
                <Col xs={12} lg={{ offset: 1, span: 10 }}>
                  <h2 className="text-2xl mb-2">Related Specials</h2>
                </Col>
              </Row>
            </Container>
            <div className="mb-8">
              <RelatedCarousel
                specials={relatedSpecials.filter(
                  (sp) => sp.subspecial_id !== subId
                )}
                current={subId}
              />
            </div>
          </div>
        )}
        <ModalContainer />
      </>
    );
  } else {
    return null;
  }
};

export default LandingPage;
