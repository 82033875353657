import React, { useContext, useState, useEffect } from "react";
import { Modal, Button, Dropdown } from "react-bootstrap";

import { MainContext } from "../../Context/MainContext";
import UilMapMarker from "@iconscout/react-unicons/icons/uil-map-marker";
import UilPhone from "@iconscout/react-unicons/icons/uil-phone";
import UilEnvelopeAlt from "@iconscout/react-unicons/icons/uil-envelope-alt";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";
import UilAngleDown from "@iconscout/react-unicons/icons/uil-angle-down";
import { useParams } from "react-router-dom";
import axios from "axios";

const ContactModal = () => {
  const { MainState, triggerModal, gaEvent } = useContext(MainContext);
  const [currentStore, setCurrentStore] = useState(null);
  const [imageReady, setImageReady] = useState(false);
  const [sent, setSent] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    details: "",
  });
  const BASE_URL = process.env.REACT_APP_BASE_URL;

  const staticDealerships = MainState.staticDealerships;
  const activeSpecial = MainState.activeSpecial;
  const vehicle = activeSpecial.vehicle;
  const saleType = activeSpecial.sale_type;

  const emailAddress = currentStore ? currentStore.email : "";
  const emailSubject = `Special: [${activeSpecial.id}-${activeSpecial.subspecial_id}] ${vehicle.year} ${vehicle.make} ${vehicle.model} ${vehicle.series}`;
  const emailBody = `I'm interested in this special: ${specialLink}`;

  const { dealerId } = useParams();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const specialLink = `${window.location.origin}/specials/${activeSpecial.id}/${activeSpecial.subspecial_id}/${vehicle.year}/${vehicle.make}/${vehicle.model}/${activeSpecial.stock_number}`;

  const availableStores = dealerId
    ? staticDealerships.filter(
        (store) =>
          store.vauto_id === dealerId &&
          (currentStore ? currentStore.id !== store.id : true)
      )
    : activeSpecial.vehicle.new === true
    ? activeSpecial.dealerships
        .filter((id) => (currentStore ? currentStore.id !== id : true))
        .map((id) => staticDealerships.find((dealer) => dealer.id === id))
    : staticDealerships
        .filter((store) =>
          currentStore
            ? false
            : activeSpecial.vehicle.dealer_id === store.vauto_id
        )
        .map((store) =>
          staticDealerships.find((dealer) => dealer.id === store.id)
        );

  useEffect(() => {
    if (!currentStore && availableStores.length === 1) {
      handleDealershipChange(availableStores[0]);
    }
  }, [availableStores]);

  const storeList = availableStores.map((store) => {
    return (
      <Dropdown.Item
        as="button"
        onClick={() => handleDealershipChange(store)}
        className="px-3"
        key={store.id}
      >
        <span className="text-blue-600 font-weight-semibold">{store.name}</span>
      </Dropdown.Item>
    );
  });

  const handleDealershipChange = (store) => {
    gaEvent("contact", "chooseDealership", store.name);
    setCurrentStore(store);
  };

  const formatPercent = (value) => {
    const pct = value.substring(0, 3);
    return pct.endsWith(".") ? pct + 0 : pct;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      ...formData,
      vehicle: {
        year: vehicle.year,
        make: vehicle.make,
        model: vehicle.model,
        vin: vehicle.vin,
        stock_number: activeSpecial.stock_number,
      },
      price: activeSpecial.sale_price,
      sendAddress: emailAddress,
      subject: emailSubject,
      body: emailBody,
    };

    axios
      .post(`${BASE_URL}/api/send-email`, data, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        console.log(response);
        //triggerModal();
        setSent(true);
        setFormData({
          name: "",
          email: "",
          phone: "",
          details: "",
        });
      })
      .catch((error) => {
        console.log("An error occurred while sending the email", error);
      });
  };

  const saleTypeText =
    saleType === "lease"
      ? `Lease Deal: $${Number(
          activeSpecial.sale_payment
        ).toLocaleString()}/month`
      : saleType === "apr"
      ? `APR Deal: $${formatPercent(activeSpecial.sale_apr)}% for ${
          activeSpecial.sale_term
        } months`
      : saleType === "finance"
      ? `APR Deal: $${Number(
          activeSpecial.sale_payment
        ).toLocaleString()}/month for ${activeSpecial.sale_term} months`
      : `Buy Deal: $${Number(activeSpecial.sale_price).toLocaleString()}`;

  const phoneNumber = currentStore ? currentStore.sales_phone : "";
  const phoneLink = `tel:${phoneNumber}`;

  const ChooseStore = () => (
    <div>
      <div className="p-4">
        <span className="text-lg font-weight-bold">Select a Dealership</span>
      </div>
      <div className="position-absolute top-0 right-0">
        <Button
          onClick={() => triggerModal()}
          variant="link-gray-700"
          className="h-12 w-12 p-0 d-flex align-items-center justify-content-center transition-none"
        >
          <UilTimes color="currentColor" />
        </Button>
      </div>
      <div className="pt-4">
        {availableStores.map((store, idx) => (
          <div className="px-4 mb-4" key={idx}>
            <Button
              block
              variant="outline-slate-500"
              onClick={() => handleDealershipChange(store)}
              className="text-left bg-white text-truncate"
            >
              <div className="leading-none">
                <div className="text-black text-truncate font-weight-semibold text-lg">
                  <span>{store.name}</span>
                </div>
                {store.subname && (
                  <div className="mb-1">
                    <span className="font-weight-medium text-xs text-gray-700 text-capitalize">
                      {store.subname}
                    </span>
                  </div>
                )}
              </div>
              <div className="d-flex align-items-center text-blue-600">
                <div className="d-flex ml-n1 mr-1">
                  <UilMapMarker size="18" color="currentColor" />
                </div>
                <div className="text-truncate text-sm font-weight-semibold">
                  <span>
                    {store.street} {store.city}, {store.state} {store.zip}
                  </span>
                </div>
              </div>
            </Button>
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      <Modal.Body className="position-relative p-0">
        {!currentStore ? (
          <ChooseStore />
        ) : (
          <div>
            <div className="position-relative">
              <div className="embed-responsive embed-responsive-16by9 rounded-top">
                <div className="embed-responsive-item bg-light">
                  <img
                    className="h-100 w-100 object-cover transition-fast"
                    src={currentStore.image_url}
                    alt={currentStore.name}
                    onLoad={() => setImageReady(true)}
                    style={{ opacity: imageReady ? "1" : "0" }}
                  />
                </div>
              </div>
              <div className="position-absolute top-0 right-0 p-2">
                <Button
                  onClick={() => triggerModal()}
                  variant="black"
                  className="h-8 w-8 p-0 d-flex align-items-center justify-content-center opacity-80 rounded-circle transition-none"
                >
                  <span className="d-flex opacity-100">
                    <UilTimes color="currentColor" />
                  </span>
                </Button>
              </div>
            </div>
            <div className="px-6 pt-2 pb-4">
              <div className="mb-3 leading-snug">
                <div>
                  <span className="text-xs text-gray-700">Available at</span>
                </div>
                <div>
                  <div className="leading-none">
                    <span className="text-xl font-weight-semibold">
                      {currentStore.name}
                    </span>
                  </div>
                  {currentStore.subname && (
                    <div className="mb-1">
                      <span className="font-weight-medium text-xs text-gray-700 text-capitalize">
                        {currentStore.subname}
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div className="d-flex text-blue-600 mb-6 align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="d-flex ml-n1 mr-1">
                    <UilMapMarker size="20" color="currentColor" />
                  </div>
                  <div className="text-sm font-weight-semibold">
                    <address className="d-flex leading-tight mb-0 leading-snug">
                      {currentStore.street}
                      <br /> {currentStore.city}, {currentStore.state}{" "}
                      {currentStore.zip}
                    </address>
                  </div>
                </div>
                {storeList.length > 0 && (
                  <div>
                    <Dropdown alignRight>
                      <Dropdown.Toggle
                        // This bsPrefix removes the .dropdown-toggle class that shows the default caret
                        bsPrefix=":"
                        className="text-decoration-none d-flex align-items-center btn-sm"
                        variant="link-blue-500"
                        id="locations"
                      >
                        <span className="font-weight-bold">
                          {storeList.length} More Location
                          {storeList.length > 1 ? "s" : ""}
                        </span>
                        <span>
                          <UilAngleDown size="25" color="currentColor" />
                        </span>
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="shadow">
                        {storeList}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )}
              </div>
              <div className="rounded bg-slate-100 p-3 shadow-sm border border-slate-500">
                <div className="text-sm text-gray-800 d-flex mb-1">
                  <span className="mr-1">Ask about:</span>
                  <strong className="font-weight-semibold">
                    {saleTypeText}
                  </strong>
                </div>
                <div className="leading-snug">
                  <div>
                    <span
                      className={`d-flex text-lg font-weight-semibold ${
                        vehicle.make.toLowerCase() === "nissan"
                          ? "font-nissan-brand"
                          : vehicle.make.toLowerCase() === "infiniti"
                          ? "font-infiniti-brand"
                          : ""
                      }`}
                    >
                      {vehicle.year} {vehicle.make} {vehicle.model}{" "}
                      {vehicle.series}
                    </span>
                  </div>
                  <div>
                    <span className="d-flex text-sm">
                      {vehicle.body}
                      {vehicle.drivetrain && ` • ${vehicle.drivetrain}`}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            {sent ? <div className="px-6 pt-4 pb-6 border-top">
                <b>Your message has been sent, we will reach out to you soon.</b>
                </div>
              : <div className="px-6 pt-4 pb-6 border-top">
              <form id="emailForm" onSubmit={handleSubmit}>
                <div className="mb-4">
                  <input
                    placeholder="Name"
                    className="py-6 mb-4 form-control"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                  ></input>
                  <input
                    placeholder="Email"
                    className="py-6 mb-4 form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  ></input>
                  <input
                    placeholder="Phone"
                    className="py-6 mb-4 form-control"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                  ></input>
                  <textarea
                    placeholder="Message"
                    rows={4}
                    className="form-control mb-4"
                    name="details"
                    value={formData.details}
                    onChange={handleChange}
                  ></textarea>
                </div>
                <Button
                  type="submit"
                  // className="btn btn-block"
                  variant="blue-500"
                  block
                >
                  Send
                </Button>
              </form>
            </div>}
            {/* <div className="px-6 pt-4 pb-6 border-top">
              <div className="mb-4">
                <Button
                  href={phoneLink}
                  onClick={() => gaEvent("contact", "click", "call")}
                  variant="blue-500"
                  block
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-4">
                      <UilPhone size="24" color="currentColor" />
                    </div>
                    <div className="text-left leading-snug">
                      <div>
                        <span className="text-uppercase text-sm tracking-wider font-weight-bold">
                          Call
                        </span>
                      </div>
                      <div>
                        <span className="text-lg font-weight-semibold">
                          {phoneNumber.replace(
                            /(\d{3})(\d{3})(\d{4})/,
                            "$1-$2-$3"
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => gaEvent("contact", "click", "email")}
                  href={emailLink}
                  variant="blue-500"
                  block
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-4">
                      <UilEnvelopeAlt size="24" color="currentColor" />
                    </div>
                    <div className="text-left leading-snug text-truncate">
                      <div>
                        <span className="text-uppercase text-sm tracking-wider font-weight-bold">
                          Email
                        </span>
                      </div>
                      <div className="text-truncate">
                        <span className="text-lg font-weight-semibold">
                          {emailAddress}
                        </span>
                      </div>
                    </div>
                  </div>
                </Button>
              </div>
            </div> */}
          </div>
        )}
      </Modal.Body>
    </>
  );
};

export default ContactModal;
