import React from "react";

const SpecialBadge = ({ condition, type }) => {
  const BadgeRight = () => (
    <div
      className={`d-flex shadow-sm border rounded ${
        type === "apr"
          ? "border-green bg-green"
          : type === "finance"
          ? "border-orange bg-orange"
          : type === "lease"
          ? "border-blue-600 bg-blue-600"
          : "border-red bg-red"
      }`}
    >
      <span className="d-flex px-1 text-xs font-weight-semibold text-white">
        {type === "apr"
          ? "APR"
          : type === "finance"
          ? "Finance"
          : type === "lease"
          ? "Lease"
          : "Buy"}
      </span>
    </div>
  );

  const BadgeLeft = () => (
    <div className="d-flex shadow-sm rounded border border-slate-500 bg-white mr-1">
      <span className="d-flex px-1 text-xs font-weight-semibold text-gray-700 ">
        {condition ? "New" : "Used"}
      </span>
    </div>
  );

  return (
    <div className="d-flex">
      {condition !== undefined && <BadgeLeft />}
      <BadgeRight />
    </div>
  );
};

export default SpecialBadge;
