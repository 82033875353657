import React, { useContext } from "react";

import { MainContext } from "../../Context/MainContext";
import { Button, Modal } from "react-bootstrap";
import MobileSort from "../Sort/MobileSortContainer";
import UilTimes from "@iconscout/react-unicons/icons/uil-times";

const MobileSortModal = () => {
  const { triggerModal } = useContext(MainContext);

  return (
    <>
      <Modal.Body className="p-0">
        <div className="position-relative p-4 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="text-lg font-weight-bold">Sort</span>
          </div>
          <div className="position-absolute top-0 right-0">
            <Button
              onClick={() => triggerModal()}
              variant="link-gray-700"
              className="h-12 w-12 p-0 d-flex align-items-center justify-content-center transition-none"
            >
              <UilTimes color="currentColor" />
            </Button>
          </div>
        </div>
        <div className="px-4 py-2 border-top">
          <MobileSort />
        </div>
      </Modal.Body>
    </>
  );
};

export default MobileSortModal;
