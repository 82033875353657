import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

import Main from "./Screens/Main";
import LandingPage from "./Screens/LandingPage";

export default () => {
  return (
    <Router>
      <Switch>
        <Route exact path={["/", "/specials", "/specials/only/:make"]}>
          <Main />
        </Route>
        <Route exact path="/specials/:dealerId">
          <Main />
        </Route>
        <Route exact path="/specials/:id/:subId/:year/:make/:model/:stock">
          <LandingPage />
        </Route>
        <Route
          exact
          path="/specials/:dealerId/:id/:subId/:year/:make/:model/:stock"
        >
          <LandingPage />
        </Route>
      </Switch>
    </Router>
  );
};
