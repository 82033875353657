import React from "react";
import { Container, Navbar, Nav, Button } from "react-bootstrap";
import ReactGA from "react-ga";

const GlobalNav = () => {
  return (
    <Navbar bg="white" variant="light" className="h-14 h-lg-20 py-0">
      <Container fluid="xl" className="h-100">
        <ReactGA.OutboundLink
          eventLabel="toWestHerr"
          to="https://www.westherr.com"
          target="_blank"
          className="navbar-brand h-100 py-0 d-flex align-items-center mr-0"
        >
          <div className="h-100">
            <img
              className="py-4 py-lg-5 h-100 w-auto"
              alt="West Herr Logo"
              src="/assets/img/west-herr-logo.png"
            />
          </div>
        </ReactGA.OutboundLink>
        <div>
          <span className="font-weight-bold ml-2 ml-lg-8">
            <span className="text-sm text-sm-base text-lg-2xl">
              <span className="d-none d-sm-inline">West Herr</span> Specials
            </span>
          </span>
        </div>
        <Nav className="ml-auto align-items-center">
          <div className="mr-2 mr-md-6">
            <Button
              href="mailto:mlasher@westherr.com?subject=Specials%20Feedback&body=Thanks%20for%20providing%20feedback%20on%20our%20specials%20site.%20Here%20are%20a%20few%20things%20we'd%20love%20to%20know%3A%0A%0A-%20What%20you%20like%0A-%20What%20you%20don't%20like%0A-%20Things%20we%20should%20change%20or%20improve%0A%0AThanks!"
              variant="link-blue-500"
              size="sm"
              className="font-weight-semibold p-1"
            >
              <span className="d-none d-sm-inline">Give</span> Feedback
            </Button>
          </div>
        </Nav>
      </Container>
    </Navbar>
  );
};

export default GlobalNav;
