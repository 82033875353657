import React from "react";

import { MainProvider } from "./Context/MainContext";

import Routes from "./Routes";

function App() {
  return (
    <div className="App">
      <MainProvider>
        <Routes />
      </MainProvider>
    </div>
  );
}

export default App;
